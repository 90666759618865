<template>
  <!-- Cette comparaison est utilisée pour ne
   pas devoir changer le champ url dans le CMS qui
   impacterait les datas sur plusieurs pages -->
  <div
    v-if="content.url && route.path !== content.url"
    id="container"
    class="video wrapper"
  >
    <div id="inner" class="video-inner">
      <h2 v-if="content.title" class="video__title" v-html="content.title"></h2>
      <div v-if="content.text" class="video__text" v-html="content.text"></div>

      <div class="video__player">
        <Player :content="{ url: content.url }" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import Player from '@/components/global/Player.vue'

import type { PropType } from 'vue'

const route = useRoute()

export interface Video {
  title: string
  text: string
  url: string
}

defineProps({
  content: {
    type: Object as PropType<Video>,
    required: true,
  },
})
</script>

<style lang="scss" scoped>
.video {
  margin: 0 auto;
  text-align: center;
}

.video__title {
  margin-bottom: 0.5em;
}

.video__text {
  display: block;
}

.video__player {
  margin: 4rem 0;

  @include mq(m) {
    margin: 6rem 0;
  }
}
</style>
