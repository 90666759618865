<template>
  <div class="documents">
    <div class="documents__heading p-xxxl wrapper">
      <h1
        class="documents__heading__title"
        :class="`title-${content.titleColor}`"
      >
        {{ content.title }}
      </h1>
      <div
        class="documents__heading__baseline h3 mt-s"
        v-html="content.baseline"
      ></div>
    </div>

    <div class="documents__filters p-xxxl">
      <form class="documents__filters__form wrapper" @submit.prevent="">
        <fieldset class="documents__filters__form__fieldset">
          <legend class="category mb-s">
            {{ t('documents.choisissez-votre-profil') }}
          </legend>
          <div
            v-for="(profile, key) in content.profiles"
            :key="key"
            class="form-switcher"
          >
            <input
              :id="key"
              ref="checkboxes"
              type="checkbox"
              name="profile"
              :checked="filters.profile === key"
              :value="key"
              @change="onCheckboxFilterChange('profile', key)"
            />
            <label :for="key">{{ profile }}</label>
          </div>
        </fieldset>

        <fieldset class="documents__filters__form__fieldset mt-xl">
          <legend class="category mb-s">
            {{ t('documents.filtrer-sur') }}
          </legend>

          <UiDropdown
            id="solutions"
            :options="getOptions('solutions')"
            :label="t('documents.type-de-solution')"
            :value="filters.solution || 'all'"
            class="form-dropdown"
            @input="onFilterChange('solution', $event)"
          />

          <UiDropdown
            id="types"
            :options="getOptions('types')"
            :label="t('documents.type-de-document')"
            :value="filters.type || 'all'"
            class="form-dropdown"
            @input="onFilterChange('type', $event)"
          />
        </fieldset>
      </form>
    </div>

    <div class="documents__results p-xxxl wrapper">
      <div class="documents__results__count category mb-xxs">
        {{ documents?.length || 0 }} {{ t('documents.documents') }}

        <Transition name="g-fade">
          <UiLoader v-show="isLoading" class="documents__results__loader" />
        </Transition>
      </div>

      <ul
        v-if="documents.length"
        class="documents__results__list"
        :class="{ 'is-loading': isLoading }"
      >
        <li
          v-for="item in documents"
          :key="item.file"
          class="documents__results__item"
        >
          <a
            :href="item.file"
            target="_blank"
            class="documents__results__item-inner pt-m pb-s"
            @focus="onItemHighlight"
            @mouseenter="onItemHighlight"
          >
            {{ item.title }}

            <div
              class="documents__results__item__extension bg-pink"
              :class="`bg-${colors[item.extension]}`"
            >
              {{ item.extension }}
            </div>
          </a>
        </li>
      </ul>

      <div class="documents__results__background"></div>
    </div>

    <CtaText v-if="content.cta" :content="content.cta" class="m-xxxl" />
  </div>
</template>

<script setup lang="ts">
import { useAxios } from '@monofront/vue-axios-plugin'
import { onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import CtaText from '@/components/cta/Text.vue'
import UiDropdown from '@/components/ui/Dropdown.vue'
import UiLoader from '@/components/ui/Loader.vue'
import { getApiUrl } from '@/config/app'
import { useCountry } from '@/plugins/country'

import type { PropType } from 'vue'
import type { CtaText as CtaTextType } from '@/components/cta/Text.vue'

interface Document {
  file: string
  title: string
  extension: 'pdf' | 'docx' | 'zip'
}

interface Documents {
  title: string
  titleColor?: string
  baseline: string
  profiles: Record<string, string>
  solutions: Record<string, string>
  types: Record<string, string>
  cta?: CtaTextType
}

const props = defineProps({
  content: {
    type: Object as PropType<Documents>,
    required: true,
  },
})

const { t } = useI18n()
const route = useRoute()

const documents = ref<Document[]>([])
const isLoading = ref(false)
const checkboxes = ref<HTMLInputElement[]>([])
const backgroundTop = ref('')
const backgroundHeight = ref('')
const axios = useAxios()
const country = useCountry()

const colors = {
  pdf: 'pink',
  docx: 'cobalt',
  zip: 'olive',
}
const filters: Record<string, string> = {
  profile: '',
  solution: '',
  type: '',
}

// Update filters based on query string
Object.keys(route.query).forEach(key => {
  if (
    typeof route.query[key] === 'string' &&
    Object.keys(filters).includes(key)
  ) {
    filters[key] = route.query[key] as string
  }
})

// Format options into array
const getOptions = (type: 'solutions' | 'types') => {
  const options = Object.keys(props.content[type]).map(key => ({
    label: props.content[type][key],
    value: key,
  }))

  options.unshift({
    label: t('global.tout'),
    value: 'all',
  })

  return options
}

// Fetch documents based on filters
const fetchDocuments = async () => {
  const apiUrl = getApiUrl(country)
  const url = `${apiUrl}/documents?${new URLSearchParams(filters)}`

  isLoading.value = true

  try {
    const response = await axios.get(url)

    if (response.status < 300) {
      documents.value = response.data
      updateURL()
    }
  } catch (e) {
    console.error(e)
  }

  isLoading.value = false
}

// Set height and top of background
const updateBackground = (el: HTMLElement) => {
  const { height, top } = el.getBoundingClientRect()

  backgroundTop.value = `${top + window.scrollY}px`
  backgroundHeight.value = `${height}px`
}

// Add filters as query params in URL
const updateURL = () => {
  const searchParams = new URLSearchParams()
  const url = new URL(window.location.href)

  Object.keys(filters).forEach(name => {
    if (filters[name] && filters[name] !== '') {
      searchParams.set(name, filters[name])
    }
  })

  url.search = searchParams.toString()
  history.replaceState(history.state, '', url)
}

// Update filters values
const onFilterChange = (name: string, value: string) => {
  filters[name] = value === 'all' ? '' : value
  fetchDocuments()
}

// Update filters values
const onCheckboxFilterChange = (name: string, value: string) => {
  checkboxes.value.forEach(element => {
    if (element.value === value) {
      if (element.checked) {
        filters[name] = element.value
      } else {
        filters[name] = ''
      }
    } else {
      element.checked = false
    }
  })

  fetchDocuments()
}

// Position background
const onItemHighlight = (e: MouseEvent | FocusEvent) => {
  const { target } = e

  if (target instanceof HTMLElement) {
    updateBackground(target)
  }
}

onBeforeMount(() => {
  fetchDocuments()
})
</script>

<style lang="scss" scoped>
.documents {
  /* stylelint-disable value-keyword-case */
  --bg-top: v-bind(backgroundTop);
  --bg-height: v-bind(backgroundHeight);
  /* stylelint-enable value-keyword-case */
}

.documents__heading__title {
  @extend %fw-bold;
  @include section-title;
}

.documents__filters {
  background: $c-secondary;
}

.documents__filters__form__fieldset {
  padding: 0;
  border: 0;

  label {
    text-transform: capitalize;
  }

  > *:last-child {
    margin-top: $spacing;
  }

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing;

    legend {
      width: 100%;
    }

    div {
      flex: 1;
    }

    > *:last-child {
      margin-top: 0;
    }
  }
}

.documents__results__count {
  position: relative;
  width: fit-content;
}

.documents__results__loader {
  --loader-size: 2rem;

  position: absolute;
  top: -0.25rem;
  right: calc(calc(var(--loader-size) * -1) - #{$spacing * 0.5});
}

.documents__results__list {
  @extend %list-nostyle;

  transition: opacity 0.5s;

  &.is-loading {
    opacity: 0;
  }
}

.documents__results__item {
  transition: border-color 0.2s;

  & + & {
    border-top: 0.1rem solid $c-grey-light;
  }

  &:hover,
  &:hover + & {
    border-color: transparent;
    transition: border-color 0.1s 0.1s;
  }
}

.documents__results__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: col(2, var(--available-columns));
  color: var(--c-text);
}

.documents__results__item__extension {
  padding: $spacing * 0.2 $spacing * 0.9;
  color: var(--c-accent);
  background: var(--c-accent-10);
  border: 0.1rem solid var(--c-accent-border);
  border-radius: 1rem;
}

.documents__results__background {
  position: absolute;
  z-index: -1;
  top: var(--bg-top);
  left: 0;
  display: none;
  width: 100vw;
  height: var(--bg-height);
  transition: 0.2s $ease-in-out;
  transition-property: background;
  will-change: background, height, top;

  ul + & {
    display: block;
  }

  .documents__results:hover & {
    background: $c-secondary;
    transition-property: background, height, top;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
</style>
